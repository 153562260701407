import React, { createContext, useContext, useState } from "react";

// Creating a new context
const FormDataContext = createContext();

// Custom hook to access the form data context
export const useFormData = () => useContext(FormDataContext);

// Provider component to wrap around your application
export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};

import React from "react";

const PoweredByGwala = () => (
  <div>
    <p className="text-[14px] leading-[20.09px] text-center text-[#444444] font-sans400 mb-[-5px]">
      Powered by Ourgwala
    </p>
    <a href="https://www.ourgwala.com" target={"_blank"} rel="noreferrer">
      <p className="text-[11px] leading-[20.09px] text-center text-[#438AFB] font-sans400">
        www.ourgwala.com
      </p>
    </a>
  </div>
);

export default PoweredByGwala;

import { BrowserRouter } from "react-router-dom";
import RequestProvider from "./components/general/RequestProvider";
import AppRoutes from "./Routes";
import { Toaster } from 'react-hot-toast'
import { FormDataProvider } from "./components/general/FormContext";

function App() {
  return (
    <div className="App">
      <Toaster />
      <BrowserRouter>
        <RequestProvider>
          <FormDataProvider>
            <AppRoutes />
          </FormDataProvider>
        </RequestProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useContext, useState } from "react";
import { RequestContext } from "../../contexts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatAmount } from "../../utils/functions";

const BankDetailSlide = () => {
  const { request } = useContext(RequestContext);
  const [copied, setCopied] = useState(false);

  return (
    <>
      <p className="text-center w-[300px] mx-auto font-sans400 text-lg text-[#545454] mb-6 leading-normal">
        Use the account details below to pay {request?.groupName}
      </p>
      <div className="flex flex-col gap-4">
        <div className="rounded-[10px] bg-[#FAFAFA] px-[15px] py-3">
          <h2 className="text-[#444444] text-sm font-sans400 mb-[4px]">
            Requested amount
          </h2>
          <p className="text-black text-lg font-mcLaren">
            {formatAmount(request?.requestedAmount)}
          </p>
        </div>
        <div className="rounded-[10px] bg-[#FAFAFA] px-[15px] py-3">
          <h2 className="text-[#444444] text-sm font-sans400 mb-[4px]">
            Group account name
          </h2>
          <p className="text-black text-lg font-sans400">
            {request?.groupName}
          </p>
        </div>
        <div className="rounded-[10px] bg-[#FAFAFA] px-[15px] py-3">
          <h2 className="text-[#444444] text-sm font-sans400 mb-[4px]">
            Bank name
          </h2>
          <p className="text-black text-lg font-sans400">Zenith Bank PLC</p>
        </div>
        <div className="rounded-[10px] bg-[#FAFAFA] px-[15px] py-3">
          <h2 className="text-[#444444] text-sm font-sans400 mb-[4px] flex justify-between">
            Group account number
            {copied && (
              <p className="text-right text-green-500">copied! &#x2713;</p>
            )}
          </h2>
          <div className="flex justify-between items-center">
            <p className="text-black text-[36px] font-mcLaren">
              {request?.groupAccountNumber}
            </p>
            <CopyToClipboard
              text={`${request?.groupAccountNumber}`}
              onCopy={() => setCopied(true)}
            >
              <p className="text-white text-xs font-sans400 px-[14px] py-[7px] bg-[#273B4A] rounded-md cursor-pointer">
                copy
              </p>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankDetailSlide;

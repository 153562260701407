import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RequestContext } from "../../contexts";
import { formatAmount } from "../../utils/functions";
import Container from "../general/Container";
import link from "../../assets/images/link.svg";
import RequiresRequest from "../general/RequiresRequest";
import PoweredByGwala from "../general/PoweredByGwala";
import BankDetailsInput from "./BanksDetailsInput";
import { toast } from "react-hot-toast";
import newEndpoints from "../../api/endpoints";
import PayersForm from "./PayersInfo";

const GroupDetails = () => {
  const params = useParams();
  const { request } = useContext(RequestContext);
  const [loading, setLoading] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showPayerForm, setShowPayerForm] = useState(false);
  const [amountInput, setAmountInput] = useState(null);
  const [isBankLoading, setIsBankLoading] = useState(false);

  const isVariableAmount =
    request?.requestedAmount === 0 || !request?.requestedAmount;

  const [data, setData] = useState();
  let disabled = isVariableAmount ? !amountInput : !request?.requestedAmount;

  let buttonOff = disabled || isBankLoading;

  useEffect(() => {
    const handleFetchBanks = async () => {
      try {
        setIsBankLoading(true);

        const { data } = await newEndpoints.getBanks();
        if (!data?.data) {
          return;
        }
        setData(data.data);
        setIsBankLoading(false);
      } catch (error) {
        toast.error(
          "There was an error fetching banks. Please refresh the page."
        );
      }
    };
    handleFetchBanks();

    const storedData = sessionStorage.getItem("yourDataKey");
    if (storedData) {
      setData(JSON.parse(storedData));
    } else {
      handleFetchBanks();
    }
  }, []);

  useEffect(() => {
    const markAsVisited = async () => {
      try {
        const { data } = await newEndpoints.markAsVisited(request?.paymentId);
      } catch (error) {
        return;
      }
    };
    if (request?.paymentId) {
      markAsVisited();
    }
  }, [request]);

  return (
    <RequiresRequest>
      {loading && (
        <div className="w-full h-screen flex items-center justify-center fixed top-0 left-0 z-[999] opacity-75 bg-white">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Container>
        <div className="bg-white w-full okay:w-small md:w-[500px] rounded-[30px] mx-auto animate__animated animate__fadeIn">
          <div className="p-5">
            {/* <div className="justify-between flex items-center mb-[39px]">
              <button
                onClick={() =>
                  navigate(
                    `/payment-requests/${params.paymentRequestInfo}/details`
                  )
                }
                className="flex items-center justify-center ml-auto h-[30px] w-[30px] rounded-[10px] border-[2px] border-primary"
              >
                <QuestionMark />
              </button>
            </div> */}
            <h1 className="mx-auto text-[22px] text-center mt-[28px] font-sans400">
              Pay {request?.creatorName}'s group
            </h1>
            <div className="h-[67px] w-[67px] rounded-[30px] mx-auto flex items-center justify-center mb-[22px] mt-[39px]">
              <img src={link} alt="Smiling Dog" className="h-full" />
            </div>
            <div className="mb-[27px] w-[85%] mx-auto">
              <p className="font-sans400 text-[18px] text-black leading-[24px]">
                Hello,
                <br />
                <br />
                {/* <br /> */}
                {request?.creatorName}, an admin for {request?.groupName} group
                has requested a payment into their Gwala group
                {request.requestedAmount
                  ? ` for the sum of ${formatAmount(request?.requestedAmount)}`
                  : ""}
                .
              </p>
              {/* <h3 className="text-black font-sans800 text-[24px] leading-[29.11px]">
                {request?.group?.name}
              </h3>
              <p className="font-sans400 text-[18px] text-black leading-[21.83px]">
                Group created by {request?.group?.ownerName}
              </p> */}
            </div>
            <div className="desc bg-[#E9D1FD] rounded-[10px] w-[90%] mx-auto p-[13px]">
              <h1 className="font-sans400 text-[14px] text-[#444444]">
                Description/purpose
              </h1>
              <p className="font-sans400 text-[18px] text-black mt-[4px] truncate whitespace-normal max-h-28">
                {request?.description}
              </p>
            </div>
            {/* <div className="mb-[29px]">
              <p className="font-sans400 text-[18px] text-black leading-[21.83px]">
                Amount to pay
              </p>
              {isVariableAmount ? (
                <NumericFormat
                  value={amountInput}
                  className="py-3 font-sans400 text-primary text-[18px] px-4 h-[52px] block w-full border-2 border-gray-200 rounded-md focus:border-primary"
                  prefix={"₦"}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  decimalScale={2}
                  decimalSeparator="."
                  thousandSeparator=","
                  fixedDecimalScale
                  onValueChange={(values, sourceInfo) =>
                    setAmountInput(values.floatValue)
                  }
                />
              ) : (
                <h3 className="text-black font-sans800 text-[24px] leading-[29.11px]">
                  {formatAmount(request?.amount)}
                </h3>
              )}
            </div>
            <div className="mb-[42px]">
              <p className="font-sans400 text-[18px] text-black leading-[21.83px]">
                Request Date
              </p>
              <h3 className="text-black font-sans800 text-[24px] leading-[29.11px]">
                {requestDate}
              </h3>
            </div> */}
            {/* {isVariableAmount ? (
              <p className="text-sm text-black leading-[16.98px] font-sans400">
                Hello, {request?.requester} requested you to fund "
                {request?.group?.name}"
              </p>
            ) : (
              <p className="text-sm text-black leading-[16.98px] font-sans400">
                Hello, {request?.requester} requested{" "}
                {formatAmount(request?.amount)} from you
              </p>
            )} */}
            <div className="mt-[46px] flex flex-col items-center justify-center">
              <button
                onClick={() => {
                  if (request?.isInputRequired) {
                    setShowPayerForm(true);
                  } else setShowEmailInput(true);
                }}
                disabled={buttonOff}
                className="mb-[13px] rounded-[30px] text-white flex items-center justify-center bg-primary font-sans400 text-[22px] h-[62px] w-full max-w-[322px] disabled:opacity-30 disabled:cursor-not-allowed"
              >
                Pay{" "}
                {request.requestedAmount
                  ? formatAmount(request.requestedAmount)
                  : ""}
              </button>
              {/* <button
                onClick={() => navigate(routes.REJECT_REQUEST)}
                className="mb-[27px] text-black text-center font-sans800 text-[18px]"
              >
                Reject request
              </button> */}
              <div className="mt-[108px]">
                <PoweredByGwala />
              </div>
            </div>
          </div>
        </div>
        {showPayerForm && (
          <PayersForm
            hide={() => setShowPayerForm(false)}
            next={() => setShowEmailInput(true)}
          />
        )}
        {showEmailInput && (
          <BankDetailsInput
            hide={() => setShowEmailInput(false)}
            info={params.paymentRequestInfo}
            banks={data}
          />
        )}
      </Container>
    </RequiresRequest>
  );
};

export default GroupDetails;

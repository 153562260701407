import React from "react";
import { useForm } from "react-hook-form";
import { useFormData } from "../general/FormContext";

const getInputClassNames = (hasError) =>
  `py-3 text-primary text-[18px] px-4 h-[62px] block w-full border-2 border-gray-200 rounded-md focus:border-primary pt-9 ${
    hasError
      ? "!border-[red] focus:!border-[red] !outline-red font-sans800 !text-[red]"
      : ""
  }`;

const PayersForm = ({ hide, next }) => {
  const {
    register,
    watch,

    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      fullname: "",
      email: "",
      phone_number: "",
    },
  });
  const { updateFormData } = useFormData();


  const handleClick = async () => {
    const fullname = watch("fullname");
    const email = watch("email");
    const phone = watch("phone_number");
    console.log({ fullname, email, phone_number: phone });
    updateFormData({ fullname, email, phone_number: phone });

    hide();
    next();
  };

  return (
    <div
      className="fixed cursor-pointer top-0 left-0 h-screen w-full bg-black/[.6]"
      onClick={hide}
    >
      <main
        id="content"
        role="main"
        className="w-full h-[742px] okay:w-small md:w-[500px] rounded-[30px] mt-[3.5rem] mx-auto animate__animated animate__fadeIn"
      >
        <div className="flex items-end w-full h-full">
          <div
            onClick={(e) => e.stopPropagation()}
            className=" bg-white  rounded-xl shadow-lg cursor-default okay:w-small md:w-[500px]"
          >
            <div className="">
              <div className="p-4 pb-0 sm:pb-4 sm:p-7 border-b-[1px] border-[#E5E5E5]">
                <div className="text-center">
                  <p className="mt-2 text-xl font-sans800 text-primary">
                    Share a bit more about you.
                  </p>
                  <p className="mt-[3px] font-sans400 text-lg text-[#545454] w-[300px] mx-auto leading-normal">
                    The payment link creator requires the following information
                  </p>
                </div>
                <div className="mt-3">
                  <div>
                    <div className="grid gap-y-6">
                      <div className="relative">
                        <label
                          htmlFor="fullname"
                          className="block text-[#979797] font-sans400 leading-5 text-[16px] absolute top-2 left-5"
                        >
                          Full name
                        </label>
                        <input
                          id="fullname"
                          name="fullname"
                          type="text"
                          className={getInputClassNames(!!errors.fullname)}
                          aria-describedby="fullname"
                          {...register("fullname", {
                            required: true,
                            minLength: 1,
                          })}
                        />
                      </div>
                      <div className="relative">
                        <label
                          htmlFor="acct_name"
                          className="block text-[#979797] font-sans400 leading-5 text-[16px] absolute top-2 left-5"
                        >
                          Email address
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className={getInputClassNames(!!errors.fullname)}
                          aria-describedby="email"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        {errors.email && (
                          <p id="emailError" className="error-message">
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                      <div className="relative">
                        <label
                          htmlFor="phone_number"
                          className="block text-[#979797] font-sans400 leading-5 text-[16px] absolute top-2 left-5"
                        >
                          Phone number
                        </label>
                        <input
                          id="phone_number"
                          name="phone_number"
                          className={getInputClassNames(!!errors.phone_number)}
                          aria-describedby="phone-number"
                          {...register("phone_number", {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        {!!errors.phone_number && (
                          <p
                            className="text-xs text-red-600 mt-2"
                            id="email-error"
                          >
                            Please enter a valid phone number
                          </p>
                        )}
                      </div>

                      <div className="flex items-center justify-center">
                        <button
                          className="mb-[10px] rounded-[30px] text-white flex items-center justify-center bg-primary font-sans400 text-[22px] h-[62px] w-[200px] lg:w-[322px] disabled:opacity-30 disabled:cursor-not-allowed"
                          disabled={!isValid}
                          onClick={handleClick}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-[25px] mb-6">
                <p className="text-[16px] leading-[22.96px] text-center text-[#444444] font-sans400">
                  Contribute faster with the Gwala App
                </p>
                <a
                  href="https://www.ourgwala.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <p className="text-[14px] leading-[20.09px] text-center text-[#438AFB] font-sans400">
                    Download the App
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PayersForm;

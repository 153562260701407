import { useContext } from "react";
import { RequestContext } from "../../contexts";
import moment from "moment";


const AboutGroupSlide = () => {
  const { request } = useContext(RequestContext);

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="rounded-[10px] bg-[#FAFAFA] px-[13px] py-5">
          <h2 className="text-[#444444] text-lg font-sans400 mb-[8px]">
            Group name
          </h2>
          <p className="text-black text-xl font-sans400">
            {request?.groupName}
          </p>
        </div>
        <div className="rounded-[10px] bg-[#FAFAFA] px-[15px] py-3">
          <h2 className="text-[#444444] text-lg font-sans400 mb-[8px]">
            Group description
          </h2>
          <p className="text-black text-xl font-sans400 truncate whitespace-normal max-h-28">
            {request?.description}
          </p>
        </div>
        <div className="rounded-[10px] bg-[#FAFAFA] px-[15px] py-3">
          <h2 className="text-[#444444] text-lg font-sans400 mb-[8px]">
            Created on
          </h2>
          <p className="text-black text-xl font-sans400">
            {moment(request?.createdAt).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="rounded-[10px] bg-[#FAFAFA] px-[15px] py-3">
          <h2 className="text-[#444444] text-lg font-sans400 mb-[8px]">
            Created by
          </h2>
          <p className="text-black text-xl font-sans400">
            {request?.creatorName}
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutGroupSlide;

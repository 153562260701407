import { Buffer } from "buffer";

export const encodeBase64 = (data) => {
  return Buffer.from(data).toString("base64");
};
export const decodeBase64 = (data) => {
  return Buffer.from(data, "base64").toString("ascii");
};

function removeInvalidCharacters(inputString) {
  const invalidCharsRegex = /[\x00-\x08\x0B\x0C\x0E-\x1F]/g;

  // Filter out invalid characters using the regex
  return inputString.replace(invalidCharsRegex, "");
}

export const extractRequestDetails = (params) => {
  try {
    const details = decodeBase64(params);
    const cleanedString = removeInvalidCharacters(details);

    return JSON.parse(cleanedString);
  } catch (e) {
    return null;
  }
};

export const formatAmount = (amount) =>
  new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "NGN",
  }).format(amount);

export const getTransactionRef = (groupId, amount, email) => {
  return encodeBase64(
    JSON.stringify({ group: groupId, amount, date: Date.now(), email })
  );
};

export const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "android";
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "ios";
  }
  return "android";
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg";
import Container from "../general/Container";
import { RequestContext } from "../../contexts";
import RequiresRequest from "../general/RequiresRequest";
import PoweredByGwala from "../general/PoweredByGwala";
import BankDetailSlide from "./BankDetailSlide";
import AboutGroupSlide from "./AboutGroupSlide";

const GroupDescription = () => {
  const navigate = useNavigate();
  const { request } = useContext(RequestContext);
  const [active, setActive] = useState("bank");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <RequiresRequest>
      <Container>
        <div className="bg-white rounded-[30px] w-full okay:w-small md:w-[500px] mx-auto">
          <div className="py-5 pt-[30px]">
            <div className="px-5">
              <div className="justify-between flex items-center mb-[40px]">
                <button onClick={() => navigate(-1)}>
                  <BackArrow />
                </button>
                <h1 className=" text-[22px] text-center text-black font-sans400 leading-[26.69px]">
                  Pay {request?.creatorName}'s group
                </h1>
                <button className="pointer-events-none opacity-0">
                  <BackArrow />
                </button>
              </div>
              <div className="flex gap-3 justify-center items-center mb-[34px]">
                <h2
                  className={`px-3 py-[6px] rounded-full text-[16px] font-sans400 cursor-pointer ${
                    active === "bank"
                      ? "text-white bg-[#273B4A]"
                      : "text-[#979797] bg-transparent"
                  }`}
                  onClick={() => setActive("bank")}
                >
                  Bank detail
                </h2>
                <h2
                  className={`px-3 py-[6px] rounded-full text-[16px] font-sans400 cursor-pointer ${
                    active === "group"
                      ? "text-white bg-[#273B4A]"
                      : "text-[#979797] bg-transparent"
                  }`}
                  onClick={() => setActive("group")}
                >
                  About group
                </h2>
              </div>
              {active === "bank" && <BankDetailSlide />}
              {active === "group" && <AboutGroupSlide />}
            </div>

            <div
              className={`${
                active === "bank" ? "mt-[96px]" : "mt-[126px]"
              } flex flex-col items-center justify-center px-5`}
            >
              <PoweredByGwala />
            </div>
          </div>
        </div>
      </Container>
    </RequiresRequest>
  );
};

export default GroupDescription;

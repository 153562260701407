import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { RequestContext } from "../../contexts";
import { extractRequestDetails } from "../../utils/functions";
import newEndpoints from "../../api/endpoints";
import groupsAPI from "../../api/groups";

const RequestProvider = ({ children }) => {
  const [request, setRequest] = useState({});
  const [loadingRequest, setLoadingRequest] = useState(true);
  let fullData;

  const getGroupDetails = async (dataLoaded) => {
    try {
      const { data } = await groupsAPI.getNewGroupDetails(dataLoaded);
      fullData = data
    } catch (error) {
      toast.error(
        "There was an error fetching the request details. Please refresh the page or request for another link."
      );
      return;
    }
    setRequest(fullData.data);
    setLoadingRequest(false);
  };

  const loadRequest = async (paymentRequestInfo) => {
    try {
      const dataLoaded = extractRequestDetails(paymentRequestInfo);
      if (!dataLoaded) {
        toast.error(
          "There was an error fetching the request details. Please refresh the page or request for another link."
        );
      } else {
        getGroupDetails(dataLoaded);
      }
    } catch (e) {
      setLoadingRequest(false);
      toast.error(
        "There was an error fetching the request details. Please refresh the page or request for another link."
      );
    }
  };

  const clearRequest = () => setRequest({});
  const markRequestAsSuccesful = () =>
    setRequest({ ...request, successful: true });

  return (
    <RequestContext.Provider
      value={{
        markRequestAsSuccesful,
        clearRequest,
        request,
        loading: loadingRequest,
        loadRequest,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export default RequestProvider;

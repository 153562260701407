import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import newEndpoints from "../../api/endpoints";
import { RequestContext } from "../../contexts";
import dropDown from '../../assets/icons/icons8-dropdown-24.png'
import { useFormData } from "../general/FormContext";

const getInputClassNames = (hasError) =>
  `py-3 text-primary text-[18px] px-4 h-[62px] block w-full border-2 border-gray-200 rounded-md focus:border-primary pt-9 ${
    hasError
      ? "!border-[red] focus:!border-[red] !outline-red font-sans800 !text-[red]"
      : ""
  }`;

const BankDetailsInput = ({ hide, info, banks }) => {
  const navigate = useNavigate();
  const { request } = useContext(RequestContext);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      bank: "",
      acct_number: "",
      phone_number: "",
    },
  });
  const [acctErr, setAcctErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acctName, setAcctName] = useState("");
  const { formData : personalDeets } = useFormData();

  // const [acctNum, setAcctNum] = useState(getValues()?.acct_number);
  // const [bankCoode, setBankCoode] = useState(getValues()?.bank);

  const acctNum = watch("acct_number");
  const bankCoode = watch("bank");

  const onSubmit = async (formData) => {
    if (formData.bank && formData.acct_number?.length === 10 && !acctErr) {
      const fullBankName = banks.filter(
        (bank) => bank.bankCode === formData.bank
      )[0].name;
      try {
        const { data } = await newEndpoints.submitAttended(request?.paymentId, {
          bankName: fullBankName,
          accountName: acctName,
          accountNumber: formData?.acct_number,
          phoneNumber: personalDeets?.phone_number?? formData?.phone_number,
          email: personalDeets?.email,
          fullName: personalDeets?.fullname,
        });
      } catch (error) {
        return;
      }
    }

    navigate(`/${info}/details`);
  };

  // Function to handle bank get details verification
  const handleFormSubmit = async (payload) => {
    try {
      const { data } = await newEndpoints.nameEnquiry(payload);
      if (!data?.data) {
        return;
      }
      setIsLoading(false);
      setAcctErr(false);
      setAcctName(data.data);
    } catch (error) {
      setAcctErr(true);
      setIsLoading(false);
      setAcctName("");
    }
  };

  useEffect(() => {
    const account_number = getValues().acct_number;
    const bank_code = getValues().bank;

    if (account_number?.length === 10 && bank_code) {
      setIsLoading(true);
      handleFormSubmit({
        bankCode: `${getValues().bank}`,
        accountNumber: `${getValues().acct_number}`,
      });
      return;
    }
    if (account_number.length === 0) {
      setAcctErr(false);
      return;
    }
    if (account_number.length < 10 && account_number.length !== 0 && acctName) {
      setAcctErr(true);
    }
  }, [acctNum, bankCoode]);

  return (
    <div
      className="fixed cursor-pointer top-0 left-0 h-screen w-full bg-black/[.6]"
      onClick={hide}
    >
      <main
        id="content"
        role="main"
        className="w-full h-[742px] okay:w-small md:w-[500px] rounded-[30px] mt-[3.5rem] mx-auto animate__animated animate__fadeIn"
      >
        <div className="flex items-end w-full h-full">
          <div
            onClick={(e) => e.stopPropagation()}
            className=" bg-white  rounded-xl shadow-lg cursor-default okay:w-small md:w-[500px]"
          >
            <div className="">
              <div className="p-4 pb-0 sm:pb-4 sm:p-7 border-b-[1px] border-[#E5E5E5]">
                <div className="text-center">
                  <p className="mt-2 text-xl font-sans800 text-primary">
                    Where are you paying from?
                  </p>
                  <p className="mt-[3px] font-sans400 text-lg text-[#545454] w-[300px] mx-auto leading-normal">
                    Please provide the bank account details below
                  </p>
                </div>
                <div className="mt-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid gap-y-6">
                      <div className="relative">
                        <label
                          htmlFor="bank"
                          className="block text-[#979797] font-sans400 leading-5 text-[16px] absolute top-2 left-5"
                        >
                          Select your bank
                        </label>
                        <select
                          id="bank"
                          name="bank"
                          className="py-3 pb-0 text-primary text-[18px] px-4 h-[65px] block w-full border-2 border-gray-200 rounded-md focus:border-primary pt-6"
                          {...register("bank")}
                          style={{ appearance: "none" }}
                          defaultValue={""}
                        >
                          {/* <option defaultValue></option> */}
                          {banks?.map((bank) => (
                            <option key={bank.bankCode} value={bank.bankCode}>
                              {bank.name}
                            </option>
                          ))}
                        </select>
                        <img src={dropDown} alt="" className="absolute right-3 bottom-[18px]"/>
                      </div>
                      <div className="relative">
                        <label
                          htmlFor="acct_number"
                          className="block text-[#979797] font-sans400 leading-5 text-[16px] absolute top-2 left-5"
                        >
                          Enter your bank account number
                        </label>
                        <input
                          id="acct_number"
                          name="acct_number"
                          type="number"
                          className={getInputClassNames(!!errors.acct_number)}
                          aria-describedby="acct_number"
                          {...register("acct_number", {
                            // required: true,
                            minLength: 10,
                            maxLength: 10,
                            validate: "[0-9]",
                          })}
                        />
                        {acctErr && !isLoading && (
                          <div className="absolute -bottom-4 left-0">
                            <p
                              className="text-xs text-red-600"
                              id="email-error"
                            >
                              Please enter a valid account number
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="relative">
                        <label
                          htmlFor="acct_name"
                          className="block text-[#979797] font-sans400 leading-5 text-[16px] absolute top-2 left-5"
                        >
                          Account name
                        </label>
                        <p
                          id="acct_name"
                          name="acct_name"
                          className="py-3 text-primary text-[18px] px-4 h-[65px] block w-full border-2 border-gray-200 rounded-md focus:border-primary pt-[1.9rem]"
                          // value={defaultValues?.acct_name}
                          // required
                          // disabled
                          aria-describedby="acct_name"
                          // {...register("acct_name", {
                          //   required: true,
                          // })}
                        >
                          {isLoading ? "Fetching details..." : acctName}
                        </p>
                      </div>
                      <div className="relative">
                        <label
                          htmlFor="phone_number"
                          className="block text-[#979797] font-sans400 leading-5 text-[16px] absolute top-2 left-5"
                        >
                          Phone number
                        </label>
                        <input
                          id="phone_number"
                          name="phone_number"
                          className={getInputClassNames(!!errors.phone_number)}
                          aria-describedby="phone-number"
                          {...register("phone_number", {
                            // required: true,
                            minLength: 8,
                          })}
                        />
                        {!!errors.phone_number && (
                          <p
                            className="text-xs text-red-600 mt-2"
                            id="email-error"
                          >
                            Please enter a valid phone number
                          </p>
                        )}
                      </div>

                      <div className="flex items-center justify-center">
                        <button
                          type="submit"
                          className="mb-[10px] rounded-[30px] text-white flex items-center justify-center bg-primary font-sans400 text-[22px] h-[62px] w-[200px] lg:w-[322px] disabled:opacity-30 disabled:cursor-not-allowed"
                          disabled={isLoading || acctErr || !isValid}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-[25px] mb-6">
                <p className="text-[16px] leading-[22.96px] text-center text-[#444444] font-sans400">
                  Contribute faster with the Gwala App
                </p>
                <a
                  href="https://www.ourgwala.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <p className="text-[14px] leading-[20.09px] text-center text-[#438AFB] font-sans400">
                    Download the App
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BankDetailsInput;

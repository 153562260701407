import gwalaAxios from './axios';

const groupsAPI = {
  getGroupDetails: (groupId) =>
    gwalaAxios.get(`/user/group/getWebGroup/${groupId}`),
  getNewGroupDetails: (groupId) =>
    gwalaAxios.get(`/user/PaymentLink/GetWebPaymentLink/${groupId}`),
  addReport: (payload) => gwalaAxios.post("/user/group/AddReport", payload),
  generatePaymentReference: (payload) =>
    gwalaAxios.post(
      "/user/transaction/generatePublicPaymentReference",
      payload
    ),
};

export default groupsAPI;
